// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-videoplayer-jsx": () => import("./../../../src/components/videoplayer.jsx" /* webpackChunkName: "component---src-components-videoplayer-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-baustein-jsx": () => import("./../../../src/templates/baustein.jsx" /* webpackChunkName: "component---src-templates-baustein-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-start-erwachsene-jsx": () => import("./../../../src/templates/start-erwachsene.jsx" /* webpackChunkName: "component---src-templates-start-erwachsene-jsx" */),
  "component---src-templates-textsequenz-jsx": () => import("./../../../src/templates/textsequenz.jsx" /* webpackChunkName: "component---src-templates-textsequenz-jsx" */),
  "component---src-templates-unterseite-erwachsene-jsx": () => import("./../../../src/templates/unterseite-erwachsene.jsx" /* webpackChunkName: "component---src-templates-unterseite-erwachsene-jsx" */)
}

